<script>
import { makeProp as p } from "@/util/props";
import { PROP_TYPE_OBJECT } from "@/constants/props";
import { useShowPromo } from "@/composables/useShowPromo";
import { XFormSelect } from "@/components/ui-components/form-select2";
import { XFormInput } from "@/components/ui-components/form-input";
import { YFormInput } from "@/components/ui-components/form-input2";
import BaseDatePicker from "@/components/Reusable/BaseDatePicker";

import BasePriceInput from "@/components/Reusable/BasePriceInput2";
import { XModalCenter } from "@/components/ui-components/modal-center";

export default {
  name: "ChCalculator",
  components: {
    XFormSelect,
    XFormInput,
    YFormInput,
    BaseDatePicker,
    BasePriceInput,
    XModalCenter,
  },
  setup() {
    return { ...useShowPromo() };
  },
  props: {
    order: p(PROP_TYPE_OBJECT, {}),
  },
  emits: ["set-v-flags"],
  data() {
    return {
      timeout: null,
      showModal: false,
      modalField: "",
      modalText: "",
      modalValue: "",
      calcRef: `ch-calculator-${this.order.apartment.uuid}`,
      oldDate: false,
      nextYear: false,
      isInit: true,
    };
  },
  computed: {
    m2Price() {
      return +(
        (this.priceCalculations.apartment_price || 0) /
        this.order.calcResult.sellable_area
      ).toFixed(2);
    },
    m2discount() {
      return +(
        (this.order.calcResult.discount_amount || 0) /
        this.order.apartment.plan.area
      ).toFixed(2);
    },
    discount() {
      if (this.order.calculation.discount === "other") {
        return this.paymentOptions.find((el) => el.type === "other");
      }
      return this.paymentOptions.find(
        (el) => el.id === this.order.calculation.discount
      );
    },
    installment() {
      return this.discount.installment_months.find(
        (el) => el.id === this.order.calculation.currentInstallment
      );
    },
    priceCalculations() {
      if (this.order.calculation.type === "installment")
        return this.installment.price_calculations;
      return this.discount.price_calculations;
    },
    prepayAmount() {
      return this.priceCalculations.initial_payment;
    },
    paymentOptions() {
      let ds;
      if (this.order.calculation.type === "promo")
        ds = this.order.calcResult.discounts.filter((d) => d.type === "promo");
      else {
        ds = this.order.calcResult.discounts.filter((d) => d.type !== "promo");
        if (this.order.calculation.type === "installment") {
          ds = ds.filter((el) => el.id);
        }
      }
      return ds.map((d, index) => {
        let text = this.$t("apartments.view.variant");
        if (d.type === "other") {
          return {
            ...d,
            text: this.$t("apartments.view.other_variant"),
            value: "other",
            id: "other",
          };
        }
        if (d.type === "promo") {
          text += ` ${this.$t("promo.by_promo")}`;
        }
        text += `  ${index + 1} - ${d.prepay}%`;

        return {
          text,
          value: d.id,
          ...d,
        };
      });
    },
    installmentOptions() {
      return this.discount.installment_months.map((el) => ({
        value: el.id,
        text: el.months + " " + this.$t("months"),
      }));
    },
    typeOptions() {
      const types = [
        {
          text: this.$t("flexible"),
          value: "custom",
        },
      ];
      if (this.order.apartment.block.building.object.is_installment_month) {
        types.push({
          text: this.$t("tariff"),
          value: "installment",
        });
      }

      const hasPromo =
        this.order.calcResult.discounts.filter((el) => el.type === "promo")
          .length > 0;
      if (this.showPromo && hasPromo)
        types.push({
          text: this.$t("promo.name"),
          value: "promo",
        });
      return types;
    },
  },
  watch: {
    "order.calculation.first_payment_date"(v) {
      const date = new Date(v);
      const today = new Date();
      this.oldDate = false;
      this.nextYear = false;
      if (date.getFullYear() > today.getFullYear()) {
        this.nextYear = true;
      } else {
        date.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
        if (date.getTime() < +today.getTime()) this.oldDate = true;
      }
    },
  },
  created() {
    setTimeout(() => (this.isInit = false), 1000);
  },
  methods: {
    async validate() {
      return await this.$refs[this.calcRef].validate();
    },
    // NEW
    openEditModal(d) {
      this.modalValue = d.value;
      this.modalField = d.field;
      this.modalText = d.text;
      this.showModal = true;
    },
    emitModalEdit() {
      const field =
        this.modalField === "m2_discount" ? "total_discount" : this.modalField;
      const value =
        this.modalField === "m2_discount"
          ? this.modalValue * this.order.apartment.plan.area
          : this.modalValue;
      this.$emit("update-calc", {
        field,
        value,
      });
      this.showModal = false;
    },
    emitEdit(v) {
      if (this.isInit) return;
      this.$emit("update-calc", v);
    },
    emitEditLazy(v) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.emitEdit(v);
      }, 1000);
    },
  },
};
</script>

<template>
  <validation-observer :ref="calcRef" tag="div" class="ch-calculator-wrapper">
    <!--? PAYMENT Type SELECT  -->
    <validation-provider
      v-slot="{ errors }"
      rules="required"
      :name="`${$t('enter_discount')}`"
      class="cw-payment-option full"
    >
      <x-form-select
        :value="order.calculation.type"
        :error="!!errors[0]"
        :options="typeOptions"
        :placeholder="$t('plan_type')"
        value-field="value"
        @change="
          (e) =>
            emitEdit({
              field: 'type',
              value: e,
            })
        "
      />
    </validation-provider>
    <!--? PAYMENT DISCOUNT SELECT  -->
    <validation-provider
      v-if="paymentOptions.length"
      v-slot="{ errors }"
      rules="required"
      :name="`${$t('enter_discount')}`"
      class="cw-payment-option full"
    >
      <x-form-select
        :value="order.calculation.discount"
        :bilingual="true"
        :error="!!errors[0]"
        :options="paymentOptions"
        :placeholder="$t('enter_discount')"
        value-field="id"
        @change="
          (e) =>
            emitEdit({
              field: 'discount',
              value: e,
            })
        "
      />
    </validation-provider>

    <!-- Months -->
    <validation-provider
      v-if="order.calculation.type === 'custom'"
      v-slot="{ errors }"
      :rules="
        order.calculation.prepay < 100
          ? 'required|min_value:1'
          : 'required|min_value:0'
      "
      :name="`${$t('installment')}`"
      class="cw-monthly-payment full"
    >
      <x-form-input
        :value="discount.months"
        type="number"
        :currency="`${$t('month_lowercase')}`"
        :label="true"
        :max="360"
        :error="!!errors[0]"
        :placeholder="`${$t('installment')}`"
        class="w-100"
        autocomplete="off"
        @input="
          (e) =>
            emitEditLazy({
              field: 'months',
              value: e,
            })
        "
      />
      <!-- @input="(e) => emitCalc('months', e)" -->
    </validation-provider>
    <!-- FOR INSTALLMENTS -->
    <validation-provider
      v-if="order.calculation.type === 'installment'"
      v-slot="{ errors }"
      rules="required"
      :name="`${$t('enter_discount')}`"
      class="cw-payment-option full"
    >
      <x-form-select
        :value="order.calculation.currentInstallment"
        :error="!!errors[0]"
        :options="installmentOptions"
        :placeholder="$t('installment months')"
        value-field="value"
        @change="
          (e) =>
            emitEdit({
              field: 'installment',
              value: e,
            })
        "
      />
      <!-- @change="(e) => emitCalc('currentInstallment', e)" -->
    </validation-provider>
    <validation-provider
      v-if="order.calculation.type === 'promo'"
      rules="required"
      :name="`${$t('enter_discount')}`"
      class="cw-payment-option full"
    >
      <x-form-input
        :value="discount.installment_months + ' ' + $t('month')"
        :placeholder="`${$t('installment')}`"
        value-field="value"
        :readonly="true"
        style="width: 100%"
      />
      <!-- @change="(e) => emitCalc('currentInstallment', e)" -->
    </validation-provider>
    <!--? PREPAYMENT  -->
    <validation-provider
      v-slot="{ errors }"
      rules="required|min_value:0"
      :name="`${$t('prepayment')}`"
      class="cw-prepayment"
    >
      <y-form-input
        :value="priceCalculations.initial_debt_percentage"
        type="number"
        currency="%"
        :precision="2"
        :label="true"
        :max="100"
        :error="!!errors[0]"
        :placeholder="`${$t('prepayment')}`"
        class="w-100"
        @input="
          (e) =>
            emitEdit({
              field: 'initial_payment_percentage',
              value: e,
            })
        "
      />
    </validation-provider>

    <!--? INITIAL_FEE  -->
    <validation-provider
      v-slot="{ errors }"
      rules="required|min_value:0"
      :name="`${$t('payments.initial_fee')}`"
      class="cw-initial-fee"
    >
      <y-form-input
        :value="prepayAmount"
        type="number"
        :currency-symbol="true"
        :label="true"
        :precision="2"
        :error="!!errors[0]"
        :placeholder="`${$t('payments.initial_fee')}`"
        class="w-100"
        @input="
          (e) =>
            emitEdit({
              field: 'initial_payment',
              value: e,
            })
        "
      />
    </validation-provider>

    <!--! START OF ANOTHER PRICE FIELDS -->
    <!--? STARTING PRICE  -->
    <validation-provider
      v-if="order.calculation.discount === 'other'"
      v-slot="{ errors }"
      rules="required|min_value:0"
      :name="`${$t('starting_price')}`"
      class="cw-starting-price"
    >
      <y-form-input
        :value="priceCalculations.apartment_price"
        type="number"
        :label="true"
        :precision="2"
        :currency-symbol="true"
        :error="!!errors[0]"
        :placeholder="`${$t('starting_price')}`"
        class="w-100"
        @input="
          (e) =>
            emitEdit({
              field: 'apartment_price',
              value: e,
            })
        "
      />
      <!-- @input="(e) => emitCalc('price', e)" -->
    </validation-provider>

    <!--? PRICE PER M2 -->
    <validation-provider
      v-if="order.calculation.discount === 'other'"
      v-slot="{ errors }"
      rules="required|min_value:0"
      :name="`${$t('price_m2')}`"
      class="cw-price-m2"
    >
      <y-form-input
        v-model="m2Price"
        type="number"
        :currency-symbol="true"
        :label="true"
        :precision="2"
        :error="!!errors[0]"
        :placeholder="`${$t('price_m2')}`"
        class="w-100"
        @input="
          (e) =>
            emitEdit({
              field: 'apartment_price_m2',
              value: e,
            })
        "
      />
    </validation-provider>
    <!--! END OF ANOTHER PRICE FIELDS -->

    <!--? TOTAL_DISCOUNT  -->
    <validation-provider
      v-slot="{ errors }"
      :name="`${$t('total_discount')}`"
      class="cw-total-discount"
    >
      <y-form-input
        type="number"
        :currency-symbol="true"
        :label="true"
        :precision="2"
        :error="!!errors[0]"
        class="w-100"
        :placeholder="`${$t('total_discount')}`"
        :value="order.calcResult.discount_amount"
        @input="
          (e) =>
            emitEdit({
              field: 'total_discount',
              value: e,
            })
        "
      />
    </validation-provider>

    <!--? DISCOUNT_PER_M2  -->
    <validation-provider
      v-slot="{ errors }"
      :name="`${$t('discount_per_m2')}`"
      class="cw-discount-per-m2"
    >
      <y-form-input
        type="number"
        :currency-symbol="true"
        :label="true"
        :precision="2"
        :error="!!errors[0]"
        class="w-100"
        :placeholder="`${$t('discount_per_m2')}`"
        :value="m2discount"
        @input="
          (e) =>
            emitEdit({
              field: 'm2_discount',
              value: e,
            })
        "
      />
    </validation-provider>

    <!--? FIRST_PAYMENT_DATE  -->
    <validation-provider
      v-slot="{ errors }"
      :name="`${$t('first_payment_date')}`"
      rules="required"
      class="cw-first-payment-date full"
    >
      <base-date-picker
        :value="order.calcResult.initial_payment_date"
        :range="false"
        :error="!!errors[0]"
        :warning="oldDate || nextYear"
        class="data-picker w-100"
        format="DD.MM.YYYY"
        :placeholder="`${$t('first_payment_date')}`"
        icon-fill="var(--violet-600)"
        @input="
          (e) =>
            emitEdit({
              field: 'initial_payment_date',
              value: e,
            })
        "
      />
    </validation-provider>
    <div style="padding-left: 17px; color: orange" v-if="oldDate" class="full">
      {{ $t("passedDateError") }}
    </div>
    <div style="padding-left: 17px; color: orange" v-if="nextYear" class="full">
      {{ $t("nextYearSelectedError") }}
    </div>

    <!--? MONTHLY_PAYMENT_DATE  -->
    <validation-provider
      v-slot="{ errors }"
      :name="`${$t('payment_date')}`"
      rules="required"
      class="cw-monthly-payment-date full"
    >
      <base-date-picker
        :value="order.calcResult.monthly_payment_date"
        :range="false"
        :error="!!errors[0]"
        class="data-picker w-100"
        format="DD.MM.YYYY"
        :placeholder="`${$t('payment_date')}`"
        icon-fill="var(--violet-600)"
        @input="
          (e) =>
            emitEdit({
              field: 'monthly_payment_date',
              value: e,
            })
        "
      />
    </validation-provider>

    <x-modal-center
      v-if="showModal"
      :bilingual="true"
      cancel-button-text="cancel"
      apply-button-class="w-100"
      cancel-button-class="w-100"
      apply-button-text="apply"
      footer-class="d-flex justify-content-between x-gap-1"
      @close="showModal = false"
      @cancel="showModal = false"
      @apply="emitModalEdit"
    >
      <template #header>
        <h3 class="x-font-size-24px font-craftworksans color-gray-600">
          {{ $t("Изменение Параметров") }}
        </h3>
      </template>

      <template #body>
        <div class="ch-comment-body mb-4">
          <div class="ch-comment-body-comment mb-2">
            {{ modalText }}
          </div>
          <x-form-input
            type="number"
            :currency-symbol="true"
            class="w-100"
            :label="true"
            :precision="2"
            :value="modalValue"
            @input="(e) => (modalValue = e)"
          />
        </div>
      </template>
    </x-modal-center>
  </validation-observer>
</template>

<style lang="scss" scoped>
.ch-calculator-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  row-gap: 10px;
}
.full {
  grid-column: span 2;
}
.discount-per-m2 {
  border-radius: 2rem;
  background-color: var(--gray-100);
  width: 100%;
  border: 0.25rem solid var(--gray-100);
  padding: 0.75rem 1.25rem;
  & ::v-deep input {
    font-weight: 700;
    color: var(--gray-600);
  }
}
.discount-per-m2:focus-within {
  border: 0.25rem solid var(--gray-200);
  background-color: var(--gray-100);
  box-sizing: border-box;
}
</style>
