import Core from '@/services/core/index'
import { axiosBase } from '@/services/core/base'

class V4Service extends Core {
  constructor(
    axios = axiosBase({
      endpoint: '/api/v4',
    }),
  ) {
    super(axios, {
      headers: {
        'Accept-Language': '',
      },
    })
  }
  orders = {
    create: b => this.post('order/create', b),
    show: b => this.post('order/show', b),
    update: b => this.post('order/update', b)
  }
}


// eslint-disable-next-line import/prefer-default-export
export const v4ServiceApi = new V4Service()
