export function headerItems() {
  const h = {
    pageInfo: {
      title: "",
      titleHighlight: "",
    },
    page: {
      type: "string",
      path: this.$t("checkout_booking"),
    },
    breadcrumbs: [
      {
        content: {
          type: "multi_language",
          path: "objects.title",
        },
        route: {
          name: "objects",
          path: "/objects",
        },
      },
    ],
  };

  const apmTitles = this.order.apartment.number
  console.log('apm', apmTitles)
  const { object } = this.order.apartment.block.building;

  if (object) {
    console.log('object', object)
    h.breadcrumbs.push({
      content: {
        type: "string",
        path: object.name,
      },
      route: {
        name: "apartments",
        params: {
          object: object.id,
        },
      },
    });

    h.breadcrumbs.push({
      content: {
        type: "string",
        path: `${this.$t("apartment")} ${apmTitles}`,
      },
      route: {
        name: "apartment-view",
        params: {
          object: object.id,
          id: this.order.apartment.uuid,
        },
      },
    });
  }

  if (this.isCreateMode) {
    h.pageInfo = {
      title: this.$t("apartment_make_contract"),
      titleHighlight: apmTitles,
    };
  } else {
    h.pageInfo = {
      title: this.$t("edit_apartment"),
      titleHighlight: apmTitles,
    };
  }

  return h;
}
